import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "components/Layout/de"

export default function(props) {
  const header = {
    text: "MoonVision Kontakt",
    description: "Kontaktiere uns @ MoonVision !",
  }
  return (
    <Layout header={header} location={props.location}>
      <main class="white-background">
        <Container>
          <Row className="py-4">
            <Col md="8">
              <h1>Kontakt</h1>
              <p>
                Glockengasse 4, Top 105<br />
                1220 Wien / Österreich
                <br />
                Tel: +43 (1) 890 12 87
              </p>
              <p>E-Mail: office@moonvision.io</p>
            </Col>

            <Col md="4" />
          </Row>
        </Container>
      </main>
    </Layout>
  )
}
